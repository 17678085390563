.no-image-holder {
    width: 100%;
    aspect-ratio: 2/3;
    background-color: #dbdbdb;
    background-size: 50%;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    align-items: center;
    display: flex;
    justify-content: center;
}
.no-image-icon {
    color: #b5b5b5;
    font-size: 200px;
}

.max-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
}