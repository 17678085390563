.header {
    height: 4rem;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
}

.header-title {
    line-height: 4rem;
    vertical-align: middle;
    color: rgb(228, 37, 37);
}